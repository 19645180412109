import { render, staticRenderFns } from "./header.vue?vue&type=template&id=2e1da29b&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&id=2e1da29b&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WalletsModal: require('/Users/denis/Github/pomelo-grants-ui/components/wallets-modal/wallets-modal.vue').default})
